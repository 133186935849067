<!--
  PACKAGE_NAME : src\pages\ai\llm\playground
  FILE_NAME : show-prompt
  AUTHOR : hpmoon
  DATE : 2024-12-04
  DESCRIPTION : 운영관리 > LLM > Playground Chat > 프롬프트 보기
-->
<template>
  <DxPopup
    :show-title="true"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :close-on-outside-click="modal.closeOnOutsideClick"
    :show-close-button="modal.showCloseButton"
    :visible="isOpen"
    @hiding="$emit('closeModal')"
  >
    <template #content>
      <div>
        <DxScrollView class="dx-texteditor dx-editor-outlined mar_to10" width="100%" :height="643">
          <div class="pad_5" v-html="convertPrompt" />
        </DxScrollView>
      </div>
    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'white filled txt_S medium' },
            text: this.$_msgContents('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
            width: '120',
            height: '40',
            onClick: () => {
              this.$emit('closeModal');
            },
          }"
    />
  </DxPopup>
</template>

<script>
  import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
  import { DxScrollView } from "devextreme-vue/scroll-view";

  export default {
    components: {
      DxScrollView,
      DxPopup,
      DxToolbarItem,
    },

    props: {
      isOpen: Boolean,
      contentData: String,
    },

    data() {
      return {
        modal: {
          title: this.$_msgContents('LLM.WORD.SHOW_PROMPT', { defaultValue: '프롬프트 보기' }),
          minWidth: '900',
          width: '900',
          minHeight: '785',
          height: '785',
          dragEnabled: true,
          resizeEnabled: true,
          closeOnOutsideClick: false,
          showCloseButton: true,
        },
      };
    },

    computed: {
      /** @description 프롬프트 내용 변환 */
      convertPrompt() {
        return this.contentData.replaceAll('\n', '<br/>');
      },
    },

    methods: {

    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    created() {

    },

    /** @description 라이프사이클 mounted 시 호출되는 메서드 */
    mounted() {

    },
  };
</script>

<style lang="scss" scoped>
  .pad_5 {
    padding: 5px;
  }
</style>