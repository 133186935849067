var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DxPopup',{attrs:{"show-title":true,"title":_vm.modal.title,"min-width":_vm.modal.minWidth,"width":_vm.modal.width,"min-height":_vm.modal.minHeight,"height":_vm.modal.height,"drag-enabled":_vm.modal.dragEnabled,"resize-enabled":_vm.modal.resizeEnabled,"close-on-outside-click":_vm.modal.closeOnOutsideClick,"show-close-button":_vm.modal.showCloseButton,"visible":_vm.isOpen},on:{"hiding":function($event){return _vm.$emit('closeModal')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c('DxScrollView',{staticClass:"dx-texteditor dx-editor-outlined mar_to10",attrs:{"width":"100%","height":643}},[_c('div',{staticClass:"pad_5",domProps:{"innerHTML":_vm._s(_vm.convertPrompt)}})])],1)]},proxy:true}])},[_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
          elementAttr: { class: 'white filled txt_S medium' },
          text: this.$_msgContents('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
          width: '120',
          height: '40',
          onClick: () => {
            this.$emit('closeModal');
          },
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }